import React, { Component } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Typography,
    Button,
    Checkbox,
    Zoom,
    Grow,
    Collapse,
    Fade,
    Tooltip,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TNode } from '../../Features/DocumentsTree/@types';
import { ODocumentTree } from '../../Features/DocumentsTree/redux-config';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Theme from '../../Resources/Theme';
import classNames from 'classnames';
import { hasContentChild } from './resources';
import utils from '../../Resources/Utils';
import { COLORS } from './constants';
import { WithTranslationProps, withTranslations } from '../../Features/Translations/withTranslations';
import { BROWSE_LDT_COLLECTIONS_COLLAPSE } from '../../Features/Translations/translationKeys.ltd';

const locale = utils.getLocale();

interface Props extends WithStyles<typeof STYLES> {
    expandOnKey?: string;
    color?: string;
    tree: Array<TNode>;
    onOpen: Function;
    variant?: 'cld' | 'ltd';
    onClose: Function;
    onClick: Function;
    disableCheck?: boolean;
    onCheck?: Function;
    onCollapse?: Function;
    checked?: Array<string>;
    textKey?: string;
    getText?: (node: TNode) => string;
    disableLevel?: number;
    collapseText?: string;
    fallbackTextKeys?: string[];
    extendedKeywordTree?: boolean;
    onAdd?: (node: TNode) => void;
    checkDisabled?: (node: TNode) => boolean;
}

class Tree extends Component<Props & WithTranslationProps> {
    state = {
        checked: [],
    };

    open = parentId => {
        this.props.onOpen(parentId);
    };

    close = parentId => {
        this.props.onClose(parentId);
    };

    onClick = (root: TNode) => {
        if (_.isFunction(this.props.onClick)) this.props.onClick(root);
    };

    onCheck = (node: TNode) => {
        if (this.props.disableCheck) return;

        if (_.isFunction(this.props.onCheck)) this.props.onCheck(node);
    };

    collapse = () => {
        if (_.isFunction(this.props.onCollapse)) this.props.onCollapse();
    };

    render() {
        const {
            classes,
            tree,
            disableCheck = true,
            disableLevel = -1,
            variant = 'ltd',
            checked = [],
            textKey = '',
            color = '#388F85',
            expandOnKey = 'hasChildren',
            fallbackTextKeys = [],
            collapseText = 'COLLAPSE-S',
            extendedKeywordTree = false,
            checkDisabled = (node: TNode) => false,
            getTranslation
        } = this.props;

        return (
            <div className={classes.root}>
                {_.isFunction(this.props.onCollapse) && (
                    <Button
                        fullWidth
                        className={classes.stickyContainer}
                        variant='text'
                        style={{ color }}
                        onClick={this.collapse}
                    >
                        {getTranslation(BROWSE_LDT_COLLECTIONS_COLLAPSE)}
                    </Button>
                )}
                <div className={classes.container}>
                    {_.map(tree, child =>
                        constructTree(
                            disableLevel,
                            expandOnKey,
                            color,
                            disableCheck,
                            classes,
                            child,
                            0,
                            this.open,
                            this.close,
                            this.onClick,
                            this.onCheck,
                            this.props.onAdd,
                            checked,
                            textKey,
                            checkDisabled,
                            extendedKeywordTree,
                            variant,
                            fallbackTextKeys
                        )
                    )}
                </div>
            </div>
        );
    }
}

const constructTree = (
    disableLevel: number,
    expandOnKey: string,
    color: string,
    disableCheck: boolean,
    classes,
    root: TNode,
    margin = 0,
    open: Function,
    close: Function,
    click: Function,
    check: Function,
    onAdd: ((node: TNode) => void) | undefined,
    checked: Array<string>,
    textKey: string,
    checkDisabled: (node: TNode) => boolean,
    extendedKeywordTree: boolean,
    variant: 'cld' | 'ltd',
    fallbackTextKeys: string[] = []
) => {
    const getText = (node: TNode) => {
        return (
            _.get(node, `highlight[${textKey}__${locale}][0]`) ||
            _.get(node, `highlight[${textKey}][0]`) ||
            fallbackTextKeys.reduce(
                (prev, cur) => prev || _.get(node, `highlight[${cur}][0]`),
                undefined
            ) ||
            node[textKey!] ||
            fallbackTextKeys.reduce(
                (prev, cur) => prev || _.get(node, cur),
                undefined
            ) ||
            node.text
        );
    };

    const getNodebadge = (root: TNode) => {
        const {
            lfCount,
            includingChildrenLfCount,
            totalLfCount,
            totalIncludingChildrenLfCount,
        } = root;
        const _count = !extendedKeywordTree ? lfCount : totalLfCount;
        const _countIncludingChildren = !extendedKeywordTree
            ? includingChildrenLfCount
            : totalIncludingChildrenLfCount;
        const count =
            root.children && root.children.length > 0
                ? _count
                : _countIncludingChildren;
        if (count)
            return (
                <Tooltip
                    title={`${count} legal finding(s) are tagged with this keyword`}
                >
                    <span className='pill'>{count}</span>
                </Tooltip>
            );

        return <p />;
    };
    const isChildSelected =
        _.size(_.intersection(root.childrenIds || [], checked)) >= 1;
    const isExpandable = root[expandOnKey] || (root.menuChildren || 0) > 0;

    return (
        <div
            className={
                root.nodeType === 'MENU' || root.nodeType === 'LABEL'
                    ? classes.listItem
                    : ''
            }
            key={root.id}
            style={{ marginLeft: margin }}
        >
            {root.nodeType === 'MENU' && (
                <div className={classes.content}>
                    {/* root.nodeType !== 'CONTENT' && */ isExpandable && (
                        <Button
                            color='primary'
                            className={classes.actionBtn}
                            onClick={e => {
                                _.isEmpty(root.children)
                                    ? open(root.id)
                                    : close(root.id);
                            }}
                        >
                            <span
                                style={{
                                    color: !_.isEmpty(root.children)
                                        ? Theme.Colors.Error
                                        : color,
                                }}
                            >
                                [&nbsp;
                            </span>
                            <span
                                style={{
                                    transform: !_.isEmpty(root.children)
                                        ? 'rotateZ(45deg)'
                                        : 'none',
                                    color: !_.isEmpty(root.children)
                                        ? Theme.Colors.Error
                                        : color,
                                }}
                                className={classes.add_close}
                            >
                                +
                            </span>
                            <span
                                style={{
                                    color: !_.isEmpty(root.children)
                                        ? Theme.Colors.Error
                                        : color,
                                }}
                            >
                                &nbsp;]
                            </span>
                        </Button>
                    )}

                    {!disableCheck &&
                        disableLevel !== root.level &&
                        !checkDisabled(root) && (
                            <Checkbox
                                color='primary'
                                onChange={e => check(root)}
                                icon={
                                    isChildSelected ? (
                                        <CheckBoxIntermediateIcon
                                            color={color}
                                        />
                                    ) : (
                                        <CheckBoxOutlineIcon color={color} />
                                    )
                                }
                                className={
                                    !disableCheck && !isExpandable
                                        ? variant === 'cld'
                                            ? classes.checkboxAltCld
                                            : classes.checkboxAltLtd
                                        : classes.checkbox
                                }
                                checked={_.indexOf(checked, root.id) > -1}
                            />
                        )}
                    {root.nodeType === 'MENU' && (
                        <Typography
                            component='div'
                            className={
                                isExpandable || (root.menuChildren || 0) > 0
                                    ? classNames(classes.node)
                                    : variant === 'cld'
                                        ? classes.nodeAltCld
                                        : classes.nodeAltLtd
                            }
                            onClick={e => click(root)}
                        >
                            <Typography
                                variant='caption'
                                className={classes.highlightContainer}
                                dangerouslySetInnerHTML={{
                                    // __html: (_.get(root, `highlight[${textKey}][0]`) || root[textKey!] || root.text)
                                    __html: getText(root),
                                    // (_.get(root, 'lfCount') ? `<span class="pill">${_.get(root, 'lfCount')}<span>` : '')
                                }}
                            />
                            {getNodebadge(root)}
                        </Typography>
                    )}
                    {onAdd ? (
                        <Button
                            variant='contained'
                            color='secondary'
                            className={classes.addButton}
                            onClick={e => onAdd(root)}
                        >
                            <i className='material-icons'>add</i>
                        </Button>
                    ) : null}
                </div>
            )}

            {root.nodeType === 'LABEL' && (
                <div className={classes.groupLabel}>
                    {root.url ? (
                        <Typography>
                            <a target='_blank' href={root.url}>
                                {root.text}
                            </a>
                        </Typography>
                    ) : (
                        <Typography>{root.text}</Typography>
                    )}
                </div>
            )}
            {!_.isEmpty(root.children) && (
                <div className={classes.children}>
                    {_.map(root.children, child =>
                        constructTree(
                            disableLevel,
                            expandOnKey,
                            color,
                            disableCheck,
                            classes,
                            child,
                            root.nodeType === 'LABEL' ? 0 : 16,
                            open,
                            close,
                            click,
                            check,
                            onAdd,
                            checked,
                            textKey,
                            checkDisabled,
                            extendedKeywordTree,
                            variant,
                            fallbackTextKeys
                        )
                    )}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

const STYLES = theme =>
    createStyles({
        root: {
            position: 'relative',
            width: '100%',
            '& .pill': {
                background: COLORS.cldPills,
                color: 'white',
                minWidth: 20,
                padding: 3,
                textAlign: 'center',
                fontSize: 12,
                height: 20,
                minHeight: 20,
                lineHeight: '20px',
                borderRadius: 15,
                // float: 'right',
                position: 'absolute',
                right: 0,
                // marginLeft: 10
            },
        },
        stickyContainer: {
            position: 'sticky',
            top: 0,
            background: 'white',
            zIndex: 1,
            [theme.breakpoints.down('sm')]: {
                top: 50,
            },
        },
        addButton: {
            minHeight: 0,
            minWidth: 0,
            height: 23,
            width: 30,
            borderRadius: 20,
            padding: 0,
            color: 'white',
        },
        container: {
            width: 324,
            paddingBottom: 70,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                padding: '0px 12px 70px 8px',
                boxSizing: 'border-box',
            },
        },
        listItem: {
            transition: '180ms linear',
        },
        highlightContainer: {
            paddingRight: 20,
            '& span': {
                background: 'yellow',
            },
        },
        content: {
            display: 'flex',
            alignItems: 'flex-start',
            '& > span': {
                padding: '8px 3px',
                position: 'relative',
                top: 0,
            },
        },
        actionBtn: {
            minHeight: 0,
            minWidth: 0,
            padding: 'inherit 3px',
            height: 'auto',
        },
        children: {
            display: 'block',
        },
        node: {
            position: 'relative',
            justifyContent: 'space-between',
            minHeight: 32,
            boxSizing: 'border-box',
            top: 2,
            display: 'flex',
            alignItems: 'center',
            lineHeight: 1.3,
            fontWeight: 500,
            padding: '8px 20px 8px 8px',
            width: '100%',
            transition: '200ms linear',
            borderBottom: `1px dashed ${fade(Theme.Colors.Fourth, 0.4)}`,
            '&:hover': {
                cursor: 'pointer',
                background: fade(Theme.Colors.Grayscale.Grey5, 0.9),
            },
        },
        // nodeAlt: {
        //     position: 'relative',
        //     justifyContent: 'space-between',
        //     minHeight: 32,
        //     boxSizing: 'border-box',
        //     top: 2,
        //     display: 'flex',
        //     alignItems: 'center',
        //     lineHeight: 1.3,
        //     fontWeight: 500,
        //     padding: '8px 20px 8px 8px',
        //     width: '100%',
        //     transition: '200ms linear',

        //     borderBottom: `1px dashed ${fade(Theme.Colors.Fourth, 0.4)}`,
        //     '&:hover': {
        //         cursor: 'pointer',
        //         background: fade(Theme.Colors.Grayscale.Grey5, 0.9)
        //     },
        // },
        checkbox: {
            padding: '4px 2px !important',
        },
        checkboxAltLtd: {
            left: 34,
            padding: '4px 2px !important',
        },

        checkboxAltCld: {
            left: 21,
            // left: 34,
            padding: '4px 2px !important',
        },
        nodeAltLtd: {
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            position: 'relative',
            minHeight: 32,
            top: 2,
            justifyContent: 'space-between',
            lineHeight: 1.3,
            fontWeight: 500,
            width: '100%',
            transition: '200ms linear',
            marginLeft: '36px !important',
            borderBottom: `1px dashed ${fade(Theme.Colors.Fourth, 0.4)}`,
            '&:hover': {
                cursor: 'pointer',
                background: fade(Theme.Colors.Grayscale.Grey5, 0.9),
            },
        },
        nodeAltCld: {
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            position: 'relative',
            minHeight: 32,
            top: 2,
            justifyContent: 'space-between',
            lineHeight: 1.3,
            fontWeight: 500,
            width: '100%',
            transition: '200ms linear',
            marginLeft: '28px !important',
            // marginLeft: '36px !important',
            borderBottom: `1px dashed ${fade(Theme.Colors.Fourth, 0.4)}`,
            '&:hover': {
                cursor: 'pointer',
                background: fade(Theme.Colors.Grayscale.Grey5, 0.9),
            },
        },
        document: {
            color: Theme.Colors.Secondary.dark,
            width: '100%',
            padding: '4px 0px',
            cursor: 'pointer',
            paddingLeft: 10,
            borderLeft: `1px dashed ${fade(Theme.Colors.Fourth, 0.4)}`,
            '&:hover': {
                background: fade(Theme.Colors.Grayscale.Grey5, 0.9),
            },
        },
        add_close: {
            transition: '200ms linear',
        },
        groupLabel: {
            '& a': {
                textDecoration: 'none',
                color: 'inherit',
            },
            margin: '16px 0px 4px 0px',
            '& p': {
                fontWeight: 700,
            },
        },
    });

const CheckBoxOutlineIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            fill={color}
            width='24'
            height='24'
            viewBox='0 0 24 24'
        >
            <path d='M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z' />
        </svg>
    );
};
const CheckBoxIntermediateIcon = ({ color }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            fill={color}
            viewBox='0 0 24 24'
            width='24'
            height='24'
        >
            <path d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z' />
        </svg>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(STYLES)(withTranslations(Tree)));
