import React, { FC, useContext } from 'react';
import {
    createStyles,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Theme,
    Tooltip,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import TranslationModel from '../../Models/Translations';
import { TranslationContext } from './TranslationContext';
import { TranslationLocale } from '../../Models/Translations/@types';
import { SelectProps } from '@material-ui/core/Select';
import { AppContext } from '../../Contexts/AppContext';
import utilities from '../../Resources/Utils';
import { WithTranslationProps, withTranslations } from './withTranslations';

export interface LocaleSwitcherProps extends WithStyles<typeof STYLES> {
    isScc?: boolean;
}

const LocaleSwitcher: FC<LocaleSwitcherProps & WithTranslationProps> = props => {
    const { changeLocale, locale } = useContext(TranslationContext);
    const { context } = useContext(AppContext);
    const { classes, getTranslation } = props;
    const handleChange: SelectProps['onChange'] = e => {
        // changeLocale(e.target.value as TranslationLocale);
        utilities.setLocale(e.target.value as TranslationLocale);
        window.location.reload();
    };
    const language = context === 'cld' ? TranslationModel.defaultLanguages_cld : TranslationModel.defaultLanguages_ltd;
    // if (context === 'ltd') return null;

    return (
        <FormControl>
            <Select
                color='primary'
                onChange={handleChange}
                className={props.isScc ? classes.sccSelect : classes.select}
                value={locale || 'en'}
                disableUnderline
                classes={{ icon: props.isScc ? classes.sccSelectIcon : classes.selectIcon }}
                inputProps={{
                    id: 'locale-switcher',
                }}
            >
                {language.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                        <Tooltip title={option.tooltip || ''}>
                            <span>
                                {option.translationKey ? getTranslation(option.translationKey) : option.name}
                                {/* {option.name} */}
                            </span>
                        </Tooltip>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const STYLES = (theme: Theme) =>
    createStyles({
        select: {
            color: 'white',
            margin: '0 6px 0 24px',
        },
        selectIcon: {
            color: 'white',
        },
        sccSelect: {
            color: 'black',
        },
        sccSelectIcon: {
            color: 'black',
        },
    });

export default withStyles(STYLES)(withTranslations(LocaleSwitcher));
