import React, { Component, useEffect, useContext } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import RootLayout from './screens/RootLayout';
import MuiTheme from './MuiTheme';
import moment from 'moment';
import _ from 'lodash';
import momentTimezone from 'moment-timezone';
import { Typography, Fab } from '@material-ui/core';
import { ThemeContext } from './Contexts/ThemeContext';
import { useThemer } from './Hooks/useThemer';
import { TranslationContextProvider } from './Features/Translations/TranslationContext';
import useLocaleInit from './Features/Translations/useLocaleInit';
import MetaTag from './Components/MetaTag';



export let IS_MOBILE = false;

const App: React.FC = (props) => {
    useEffect(() => {
        momentTimezone.tz.setDefault('Europe/Berlin');
        IS_MOBILE = window.outerWidth <= 600;
    }, [])


    const { theme, currentTheme } = useContext(ThemeContext)
    useThemer();
    useLocaleInit();


    // componentDidUpdate() {
    //     IS_MOBILE = window.outerWidth <= 600;
    // }


    var isIE = /*@cc_on!@*/false || !!_.get(document, 'documentMode');


    return (
        <BrowserRouter>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <MuiThemeProvider theme={theme} >
                    <MetaTag />
                    <RootLayout />
                    {isIE ? <BannerForIE /> : null}
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        </BrowserRouter>
    );
}



// class App extends Component {
//     componentDidMount() {
//         momentTimezone.tz.setDefault('Europe/Berlin');
//         IS_MOBILE = window.outerWidth <= 600;
//     }

//     componentDidUpdate() {
//         IS_MOBILE = window.outerWidth <= 600;
//     }

//     render() {

//         var isIE = /*@cc_on!@*/false || !!_.get(document, 'documentMode');
//         console.log("Is internet explorer ", isIE);


//         return (
//             <BrowserRouter>
//                 <MuiPickersUtilsProvider utils={MomentUtils}>
//                     <MuiThemeProvider theme={MuiTheme} >
//                         <RootLayout />
//                         {isIE ? <BannerForIE /> : null}
//                     </MuiThemeProvider>
//                 </MuiPickersUtilsProvider>
//             </BrowserRouter>
//         );
//     }
// }



class BannerForIE extends Component {
    state = {
        open: true
    }


    render() {

        return this.state.open ? (
            <div className="flex-row align-center space-between" style={{
                background: '#000',
                position: 'fixed',
                bottom: 0, left: 0,
                right: 0, height: 40,
                color: '#fff',
                zIndex: 1610
            }}>
                <Typography className="mx-4" align="center" color="inherit" variant="h6">Please use Google Chrome, Mozilla Firefox or Microsoft Edge for optimal functionality</Typography>
                <Fab size="small" onClick={e => this.setState({ open: false })}>
                    <i className="material-icons">close</i>
                </Fab>
            </div>
        ) : null
    }
}

export default App;
