import React, { FC, useState } from 'react';
import { Button, createStyles, TextField, Theme, withStyles, WithStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { OCldDashboard } from '../../../Features/CldDashboard/redux-config';
import Tree from '../../../Components/Tree';
import THEME from '../../../Resources/Theme';
import _ from 'lodash';
import { TState } from '../../../RootReducer';
import { TNode } from '../../../Features/CLDDocumentTree/@types';
import UpdateForm, { useKeywordUpdate } from './UpdateForm';
import EditableKWTree from '../../../Components/EditableKWTree';
import { TFile } from '../../../Components/FileUpload/FileInput';


export interface KeywordTreeProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps {

}


interface IStateProps {
    tree: TNode[]
    keywordText: string
}

interface IDispatchProps {
    openNode: (parentId) => void
    closeNode: (parentId) => void
    resetTree: () => void
    setKeywordtext: (value) => void
    searchTree: (value) => void
    createNode: (node: Partial<TNode>) => Promise<TNode>
    updateNode: (id: string, node: Partial<TNode>) => Promise<TNode>
    deleteNode: (id: string, moveChildrenToParent: boolean) => Promise<TNode>
}

const KeywordTree: FC<KeywordTreeProps> = (props) => {

    const { tree, classes, setKeywordtext, searchTree, keywordText, resetTree } = props;
    const [state, setState] = useState({
        text: '',
        anchorEl: null,
        nodeId: '',
        currentNode: undefined,
        currentDocument: undefined,
    })










    const onCheck = (node: TNode) => {
        // if (this.props.onCheck)
        //     this.props.onCheck(node);
    }

    const onClick = (node: TNode) => {
    }

    const onClose = (nodeId: string) => {
        props.closeNode(nodeId);
        // if (this.props.closeNode)
        //     this.props.closeNode(nodeId)
    }

    const onOpen = async (nodeId: string) => {
        return props.openNode(nodeId)
        // if (!this.props.openNode)
        //     return;
        // try {
        //     return this.props.openNode(nodeId)
        // } catch (error) {

        // }
    }

    const onNodeShift = async (nodeId, newIndex, newParentId, nextPath, nextParent?: TNode) => {
        // if (!this.props.shiftNode)
        //     return;
        // const subTree = traverseTree(nextPath.slice(0, nextPath.length - 1), this.props.tree);
        // const targetNode = subTree[newIndex];
        // if (!targetNode)
        //     return; //TODO implement edge case.
        // const targetNodeIndex = _.indexOf(_.get(nextParent, 'childrenIds'), targetNode.id);

        // try {
        //     const res = await this.props.shiftNode(nodeId, targetNodeIndex, newParentId);
        // } catch (error) {

        // }
    }


    const addNewDoc = (node: TNode) => {
        // if (!this.props.openDocumentAddDialog || _.isEmpty(node))
        //     return;
        // this.props.openDocumentAddDialog(node.id)
    }

    const addNewNode = (node: TNode) => {
        // this.setState({ nodeId: node.id, text: '' });
        // const el = document.getElementById('anchor');
        // if (el)
        //     this.setState({ anchorEl: el, type: 'new' })
    }

    const editNode = (node: TNode) => {
        // this.setState({ text: node.text, nodeId: node.id });
        // const el = document.getElementById('anchor');
        // if (el)
        //     this.setState({ anchorEl: el, type: 'edit' })
    }

    const deleteNode = async (node: TNode, moveChildrenToParent: boolean) => {
        return await props.deleteNode(node.id, moveChildrenToParent);
    }

    const hideNode = async (node: TNode) => {
        // return await this.props.hideNode!([node.id]);
    }
    const handleRestoreNode = async (node: TNode) => {
        // return await this.props.unhideNode!([node.id]);
    }

    const updateNode = async (nodeId, data) => {
        return props.updateNode(nodeId, data);
    }

    const createNewNode = async (nodeId, data) => {
        return props.createNode({ parentId: nodeId, nodeType: 'MENU', ...data });
    }


    const onNodeClick = (node: TNode) => {
        // this.setState({ currentNode: node })
        // if (this.props.setSidebarTitle)
        //     this.props.setSidebarTitle(node.text);

        // if (this.props.applyQuery)
        //     this.props.applyQuery({
        //         parentId: node.id
        //     });
        // this.openDocumentsInNode(node);
    }


    const handleBackButtonClick = () => {
        // const { currentNode } = this.state;
        // if (this.props.setSidebarTitle && currentNode)
        //     this.props.setSidebarTitle(_.get(currentNode, 'text') || '')
        // if (this.props.openSidebar)
        //     this.props.openSidebar((
        //         <ListContainer openDocument={this.openDocument} />
        //     ))
    }

    const openDocument = (document: any) => {
        // if (!this.props.openSidebar || !this.props.fetchDocumentDetail)
        //     return;

        // this.setState({ currentDocument: document })
        // if (this.props.setSidebarTitle)
        //     this.props.setSidebarTitle(document.title)
        // this.props.fetchDocumentDetail(document.id)
        // this.props.openSidebar((
        //     <DocumentDetail onBack={this.handleBackButtonClick} />
        // ))
    }



    const openDocumentsInNode = async (node: TNode) => {
        // if (!this.props.searchDocuments || !this.props.openSidebar)
        //     return;

        // this.props.searchDocuments(0, {
        //     where: {
        //         parentId: node.id
        //     }
        // });
        // if (this.props.openSidebar)
        //     this.props.openSidebar((
        //         <ListContainer openDocument={this.openDocument} />
        //     ))

    }

    const handleCopyDocument = async (documentId, nodeId) => {
        // if (this.props.copyDocument)
        //     return this.props.copyDocument(documentId, nodeId);
    }
    const handleMoveDocument = async (documentId, nodeId) => {
        // if (this.props.moveDocument)
        //     return this.props.moveDocument(documentId, nodeId);
    }

    const handleSelectAllDocuments = () => {
        // if (!this.props.setSelection) return;
        // const { documents } = this.props;
        // const docIds = documents.map(doc => _.get(doc, 'hit.id')).filter(x => !!x);
        // this.props.setSelection(docIds)
    }

    const handleCsvUpload = async (files: TFile[], node: TNode) => {
        // if (!this.props.batchCreateFromCsvDocuments) return;
        // if (files.length < 1) return;
        // let content = (files[0].base64 || '') as string;
        // const { forms, labelKeys } = await csvUtil.buildFormFormCsv(content, {
        //     ...this.props.appconstants, ...this.props.ltdIncludeOptions,
        // });
        // const _formToEdit: TBatchEditForm = {};
        // forms.forEach((form, i) => {
        //     _formToEdit[i.toString() + '-temp-id'] = form
        // })
        // this.props.batchCreateFromCsvDocuments(labelKeys, node, _formToEdit)
    }



    return (
        <div>
            <div id="anchor" />
            <EditableKWTree
                onClose={onClose}
                onOpen={onOpen}
                // onShiftNode={onNodeShift}
                addNode={createNewNode}
                updateNode={updateNode}
                onNodeClick={onNodeClick}
                addDoc={addNewDoc}
                deleteNode={deleteNode}
                tempDeleteNode={hideNode}
                restoreNode={handleRestoreNode}
                tree={tree}
                copyDocument={handleCopyDocument}
                moveDocument={handleMoveDocument}
            />

        </div>
    )
}

const mapStateToProps = state => ({
    ..._.pick(state.CldDashboard, ['tree', 'keywordText']),
});

const mapDispatchToProps = dispatch => ({
    openNode: (parentId) => dispatch(OCldDashboard.fetchChildren(parentId)),
    closeNode: (parentId) => dispatch(OCldDashboard.removeChildren(parentId)),
    resetTree: () => dispatch(OCldDashboard.resetTree()),
    searchTree: (value) => dispatch(OCldDashboard.treeSearch(value)),
    setKeywordtext: (value) => dispatch(OCldDashboard.setKeywordtext(value)),
    createNode: (node) => dispatch(OCldDashboard.addNewNode(node)),
    updateNode: (id, node) => dispatch(OCldDashboard.updateNode(id, node)),
    deleteNode: (id, moveChildrenToParent) => dispatch(OCldDashboard.deleteNode(id, moveChildrenToParent)),
});


const width = 500;
const STYLES = (theme: Theme) => createStyles({
    treeContainer: {
        width
    },
    treeContent: {
        '& > button': {
            padding: 0,
            marginTop: 8
        },
        '& > span': {
            width: 20,
            marginTop: 5,
            marginLeft: 4
        }
    },
    treeNode: {
        paddingRight: 0,
    },
    treeHighlightContainer: {
        paddingRight: 38
    },
    searchBar: {
        // width,
        height: 60,
        display: 'flex',
        alignItems: 'center',
        padding: '0px 16px',
        background: THEME.Colors.Grayscale.Grey5,
        // '& i': {
        //     marginRight: 8
        // }
    },
    clear: {
        borderRadius: '50%',
        padding: 0,
        minHeight: 0,
        minWidth: 0,
        // height: 'auto',
        width: 25,
        height: 22,
        background: 'gray',
        '& i': {
            margin: 0,
            color: 'white',
            fontSize: 14,
            padding: 4,
        }
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(KeywordTree));