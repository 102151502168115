// GENERAL
export const GENERAL = 'general';
export const GENERAL_SELECT_ALL = GENERAL + '.selectAll';
export const GENERAL_CLEAR_SELECTION = GENERAL + '.clearSelection';
export const GENERAL_SELECTED = GENERAL + '.selected';
export const GENERAL_MORE = GENERAL + '.more';
export const GENERAL_OK = GENERAL + '.ok';
export const GENERAL_YES = GENERAL + '.yes';
export const GENERAL_NO = GENERAL + '.no';
export const GENERAL_AND = GENERAL + '.and';
export const GENERAL_OR = GENERAL + '.or';
export const GENERAL_NOT = GENERAL + '.not';
export const GENERAL_CANCEL = GENERAL + '.cancel';
export const GENERAL_SUBMIT = GENERAL + '.submit';
export const GENERAL_ENTER = GENERAL + '.enter';
export const GENERAL_SAVE = GENERAL + '.save';
export const GENERAL_DELETE = GENERAL + '.delete';
export const GENERAL_READ_LATER = GENERAL + '.readLater';
export const GENERAL_ADD_TO_READING_LIST = GENERAL + '.addToReadingList';
export const GENERAL_NEW_READING_LIST = GENERAL + '.newReadingList';
export const GENERAL_CREATE_NEW_READING_LIST = GENERAL + '.createNewReadingList';
export const GENERAL_DOWNLOAD = GENERAL + '.download';
export const GENERAL_APPLY = GENERAL + '.apply';
export const GENERAL_RESET = GENERAL + '.reset';
export const GENERAL_HELP = GENERAL + '.help';
export const GENERAL_DASHBOARD = GENERAL + '.dashboard';
export const GENERAL_SETTINGS = GENERAL + '.settings';
export const GENERAL_READING_LIST = GENERAL + '.readingList';
export const GENERAL_LOGIN = GENERAL + '.login';
export const GENERAL_LOGOUT = GENERAL + '.logout';
export const GENERAL_SIGNUP = GENERAL + '.signup';
export const GENERAL_ADD = GENERAL + '.add';
export const GENERAL_NAME = GENERAL + '.name';
export const GENERAL_EMAIL = GENERAL + '.email';
export const GENERAL_PASSWORD = GENERAL + '.password';
export const GENERAL_REPEAT_PASSWORD = GENERAL + '.repeatPassword';
export const GENERAL_PROFILE = GENERAL + '.profile';
export const GENERAL_LOG_OUT_TITLE = GENERAL + '.logout_title';
export const GENERAL_LOG_DESCRIPTION = GENERAL + '.logout_description';

// SEARCH PANEL
export const SEARCH_PANEL = 'searchPanel';
export const SEARCH_PANEL_ADVANCED_SEARCH_BUTTON = SEARCH_PANEL + '.advancedSearchButton';
export const SEARCH_PANEL_SEARCH_INPUT_PLACEHOLDER = SEARCH_PANEL + '.searchInputPlaceholder';
export const SEARCH_PANEL_FILE_NAME = SEARCH_PANEL + '.searchFilterName';
export const SEARCH_PANEL_CASE_NAME = SEARCH_PANEL + '.searchCaseName';
export const SEARCH_PANEL_FILE_NUMBER = SEARCH_PANEL + '.searchFileNumber';
export const SEARCH_PANEL_SUBMIT_BUTTON = SEARCH_PANEL + '.searchSubmitButton';
export const SEARCH_PANEL_THIS_DATE_BUTTON = SEARCH_PANEL + '.searchThisDateButton';
export const SEARCH_PANEL_FIELDS = SEARCH_PANEL + '.clear_fields';

//CARD PANEL
export const CARD_PANEL = 'card';
export const CARD_PANEL_COPYURL_TEXT = CARD_PANEL + '.copyUrl';
export const CARD_PANEL_SEE_FULL = CARD_PANEL + '.seeFull';
export const CARD_PANEL_DOWNLOAD = CARD_PANEL + '.download';
export const CARD_PANEL_SUCCESSFULLY_ADDED = CARD_PANEL + '.successFullyAddedReadingList';
export const CARD_PANEL_LINK_COPIED_TO_CLIPBOARD = CARD_PANEL + '.linkCopiedToClipboard';
export const CARD_PANEL_SEARCH_RESULTLINK_COPIED = CARD_PANEL + '.searchResultLinkCopied';

//LISTING HEADER
export const LISTING_HEADER = 'listingHeader';
export const LISTING_HEADER_LATEST = LISTING_HEADER + '.latest';
export const LISTING_HEADER_SELECT_ALL = LISTING_HEADER + '.select';
export const LISTING_HEADER_STORED_BY = LISTING_HEADER + '.sortedBy';
export const LISTING_HEADER_RESULT_PER_PAGE = LISTING_HEADER + '.resultsPerPage';
export const LISTING_HEADER_OLDEST = LISTING_HEADER + '.oldest';
export const LISTING_HEADER_RELEVANCE = LISTING_HEADER + '.relevance';
export const LISTING_HEADER_SHARE_SEARCH = LISTING_HEADER + '.shareSearchResult';
export const LISTING_HEADER_RESET = LISTING_HEADER + '.resetSelection';
export const LISTING_HEADER_CLEAR_ALL = LISTING_HEADER + '.clearAll';
export const LIST_HEADER_TOTAL = LISTING_HEADER + '.latest_total';

//METADATA HEADER
export const METADATA_HEADER = 'ltdHeader';
export const METADATA_HEADER_COPYPURL = METADATA_HEADER + '.copyPurl';
export const METADATA_HEADER_DOWNLOAD = METADATA_HEADER + '.download';
export const META_DATA_HEADER_INFO = METADATA_HEADER + '.info';
export const META_DATA_HEADER_OPEN_PDF = METADATA_HEADER + '.openPdf';
export const METADATA_HEADER_ENG_LANG = METADATA_HEADER + '.english';
export const METADATA_HEADER_FRENCH_LANG = METADATA_HEADER + '.french';
export const METADATA_HEADER_SPANISH_LANG = METADATA_HEADER + '.spanish';
export const METADATA_HEADER_CHINESE_LANG = METADATA_HEADER + '.chinese';
export const METADATA_HEADER_RUSSIAN_LANG = METADATA_HEADER + '.russian';

//FILTER
export const FILTER = 'filterSection';
export const FILTER_COLLECTION = FILTER + '.collection';
export const FILTER_CONTENT_TYPE = FILTER + '.contentType';
export const FILTER_SOURCE_TYPE = FILTER + '.sourceTypes';
export const FILTER_ORGANIZATION_STATE = FILTER + '.organisation';
export const FILTER_SOURCE = FILTER + '.source';
export const FILTER_PHASE_OF_CASE = FILTER + '.phaseOfCases';
export const FILTER_JUDICAL_DOCUMENT = FILTER + '.judicialDocumentType';
export const FILTER_LANGUAGE = FILTER + '.languages';

//ADVANCED SEARCH
export const ADVANCED_SEARCH = 'advancedSearchDialog';
export const ADVANCED_SEARCH_FORM_QUERY = ADVANCED_SEARCH + '.formQuery';
export const ADVANCED_SEARCH_PROXIMITY_WORD = ADVANCED_SEARCH + '.formProximityOfWords';
export const ADVANCED_SEARCH_RESET_BUTTON = ADVANCED_SEARCH + '.resetBtn';
export const ADVANCED_SEARCH_SEARCH_BUTTON = ADVANCED_SEARCH + '.searchBtn';
export const ADVANCED_SEARCH_FULL_TEXT = ADVANCED_SEARCH + '.fullText';
export const ADVANCED_SEARCH_TITLE = ADVANCED_SEARCH + '.advanceSearchTitle';
export const ADVANCED_SEARCH_CASE_NAME = ADVANCED_SEARCH + '.caseName';
export const ADVANCED_SEARCH_FILE_NUMBER = ADVANCED_SEARCH + '.fileNumber';
export const ADVANCED_SEARCH_AND = ADVANCED_SEARCH + '.and';
export const ADVANCED_SEARCH_OR = ADVANCED_SEARCH + '.or';
export const ADVANCED_SEARCH_NOT = ADVANCED_SEARCH + '.not';

//BROWSE LDT COLLECTIONS
export const BROWSE_LDT_COLLECTIONS = 'browseLtdCollections';
export const BROWSE_LDT_COLLECTIONS_TITLE = BROWSE_LDT_COLLECTIONS + '.browseLtdCollections';
export const BROWSE_LDT_COLLECTIONS_COLLAPSE = BROWSE_LDT_COLLECTIONS + '.collapse';

//BROWSE LDT COLLECTIONS
export const BROWSE_SCC_COLLECTIONS = 'browseSccCollections';
export const BROWSE_SCC_COLLECTIONS_TITLE = BROWSE_SCC_COLLECTIONS + '.browseSccCollections';
export const BROWSE_SCC_COLLECTIONS_COLLAPSE = BROWSE_SCC_COLLECTIONS + '.collapse';

//MENULISTITEM
export const MENU_LIST = 'menuListItems';
export const MENU_LIST_RENAME = MENU_LIST + '.rename';
export const MENU_LIST_DELETE = MENU_LIST + '.delete';
export const MENU_LIST_SIGN_UP = MENU_LIST + '.signUp';
export const MENU_LIST_NAME = MENU_LIST + '.name';
export const MENU_LIST_EMAIL = MENU_LIST + '.email';
export const MENU_LIST_PASSWORD = MENU_LIST + '.password';
export const MENU_LIST_REPEAT_PASSWORD = MENU_LIST + '.repeatPassword';
export const MENU_LIST_CONFIRM_PASSWORD = MENU_LIST + '.confirmPassword';
export const MENU_LIST_TERM_POLICY = MENU_LIST + '.termPolicyAggriment';
export const MENU_LIST_REMOVE_ACCOUNT = MENU_LIST + '.accountRemoveRequest';
export const MENU_LIST_SUBMIT = MENU_LIST + '.submit';
export const MENU_LIST_LOGIN = MENU_LIST + '.login';
export const MENU_LIST_LOGOUT = MENU_LIST + '.logout';
export const MENU_LIST_REPORT = MENU_LIST + '.reportIssue';
export const MENU_LIST_FULLNAME = MENU_LIST + '.fullName';
export const MENU_LIST_MENU = MENU_LIST + '.menu';
export const MENU_LIST_TUTORIAL = MENU_LIST + '.tutorial';
export const MENU_LIST_ENTER = MENU_LIST + '.enter';
export const MENU_LIST_TUTORIAL_FILMS = MENU_LIST + '.tutorialfilms';
export const MENU_LIST_PERSISTENT = MENU_LIST + '.persistent';
export const MENU_LIST_HOWTOUSE_PURL = MENU_LIST + '.howToUsePurl';
export const MENU_LIST_LEGAL_TOOLS = MENU_LIST + '.legalTools';
export const MENU_LIST_LEGAL_CORDANATION = MENU_LIST + '.cordanationCMN';
export const MENU_LIST_LTAC = MENU_LIST + '.LTAC';
export const MENU_LIST_STATUS_WORK = MENU_LIST + '.statusWorkTool';
export const MENU_LIST_RESPONSIBILITY = MENU_LIST + '.tableResponsiblity';
export const MENU_LIST_BACKGROUND = MENU_LIST + '.background';
export const MENU_LIST_OPENACCESS = MENU_LIST + '.openAccessInterview';
export const MENU_LIST_BOOKONTHE = MENU_LIST + '.bookOnTheLegalTools';
export const MENU_LIST_THE_PUBILC = MENU_LIST + '.thePublicCommonsOfInternationalCriminalLawInformation';
export const MENU_LIST_LEXSISTUS = MENU_LIST + '.lexsitus';
export const MENU_LIST_COMMENTARY = MENU_LIST + '.commentaryOnThe';
export const MENU_LIST_COPPERATION = MENU_LIST + '.cooperationAndJudicial';
export const MENU_LIST_NATIONAL = MENU_LIST + '.nationalImplementing';
export const MENU_LIST_ICC_MAIN_MENU = MENU_LIST + '.iccMainMenu';
export const MENU_LIST_CONTACT_US = MENU_LIST + '.contactUs';
export const MENU_LIST_REPORT_ERROR = MENU_LIST + '.reportAnError';
export const MENU_LIST_PLEASE_DESCRIBE = MENU_LIST + '.pleaseDescribe';
export const MENU_LIST_SUGGEST_ADDITION = MENU_LIST + '.suggestAddition';
export const MENU_LIST_YOU_CAN_SUGGEST = MENU_LIST + '.youCanSuggestAddition';
export const MENU_LIST_FULL_NAME_ENABLE = MENU_LIST + '.fullNameToEnable';
export const MENU_LIST_EMAIL_ADDRESS = MENU_LIST + '.emailAddressToEnable';
export const MENU_LIST_COMMENTS = MENU_LIST + '.comments';
export const MENU_LIST_COMMENTS_IF_ANY = MENU_LIST + '.commentsIfAnyFor';
export const MENU_LIST_PLEASE_FILL = MENU_LIST + '.pleaseFillInTheMetaData';
export const MENU_LIST_TITLE = MENU_LIST + '.title';
export const MENU_LIST_TRANSLATED_TITLE = MENU_LIST + '.translatedTitle';
export const MENU_LIST_ORGANISATION_STATE = MENU_LIST + '.organisationState';
export const MENU_LIST_LANGUAGE = MENU_LIST + '.language';
export const MENU_LIST_DATE_CREATED = MENU_LIST + '.dateCreated';
export const MENU_LIST_RESOURCE_FILE = MENU_LIST + '.resourceFile';
export const MENU_LIST_ATTACH_FILE = MENU_LIST + '.attachFile';
export const MENU_LIST_URL = MENU_LIST + '.url';
export const MENU_LIST_SUGGESTION_CORRETION = MENU_LIST + '.suggestCorrection';
export const MENU_LIST_PURL = MENU_LIST + '.purl';

//TERM_AND_CONDESION
export const TERM_AND_CONDESION = 'termsAndCondesion';
export const TERM_TEXT_I_AGREE = TERM_AND_CONDESION + '.iAgreeToThe';
export const TERM_AND_CONDESION_TEXT = TERM_AND_CONDESION + '.termsAndCondesion';
export const TERM_AND_TEXT = TERM_AND_CONDESION + '.and';
export const TERM_PRIVACY_POLICY_TEXT = TERM_AND_CONDESION + '.privacyPolicy';
export const TERM_BY_REGISTER_TEXT = TERM_AND_CONDESION + '.byRegisteringAnICCLegalToolsDatabase';
export const TERM_DOCUMENT_CONTAIN_TEAM_TEXT = TERM_AND_CONDESION + '.onlyTheLDTCoordiantionTeam';
export const TERM_PRIVACY_POLICY_TEXT_SUMMARY = TERM_AND_CONDESION + '.privacyPolicy_summary';

//AUTHINCATION
export const AUTHINCATION = 'authentication';
export const AUTHINCATION_SIGNUP_HERE = AUTHINCATION + '.signUpHere';
export const AUTHINCATION_NEW_USER = AUTHINCATION + '.newUser';
export const AUTHINCATION_FORGOT_PASSWORD = AUTHINCATION + '.forgotPassword';

//Footer
export const FOOTER_TEXT = 'footer';
export const FOOTER = FOOTER_TEXT + '.footer';
export const FOOTER_TERMS_USE = FOOTER_TEXT + '.termsOfUse';
export const FOOTER_POST_TERMS_USE = FOOTER_TEXT + '.postTermsOfUse';

//METADATA PANNEL
export const METADATA_PANNEL = 'document';
export const METADATA_PANNEL_TEXT = METADATA_PANNEL + '.advancedMedataData';
export const METADATA_PERSISTENT_URL = METADATA_PANNEL + '.persistentURL';
export const METADATA_TITLE = METADATA_PANNEL + '.title';
export const METADATA_EXTERNAL_IDENTIFIER = METADATA_PANNEL + '.externalIdentifier';
export const METADATA_CONTENT_TYPE = METADATA_PANNEL + '.contentType';
export const METADATA_ORGANISATION = METADATA_PANNEL + '.organisation';
export const METADATA_RELATED_ORGANISATION = METADATA_PANNEL + '.relatedOrganisation';
export const METADATA_ORGANISATION_JUDICAL = METADATA_PANNEL + '.organisationJudicial';
export const METADATA_DATE_CREATED = METADATA_PANNEL + '.dateCreated';
export const METADATA_LANGUAGE = METADATA_PANNEL + '.language';
export const METADATA_ORIGINAL_LANGUAGE = METADATA_PANNEL + '.originalLanguage';
export const METADATA_AUTHORIATIVE_LANGUAGE = METADATA_PANNEL + '.authorItativeLanguage';
export const METADATA_ABSTRACT = METADATA_PANNEL + '.abstract';
export const METADATA_CASE_NAME_EN = METADATA_PANNEL + '.caseNameEN';
export const METADATA_CASE_NAME_FR = METADATA_PANNEL + '.CaseNameFR';
export const METADATA_DOCUMENT_NO = METADATA_PANNEL + '.caseDocumentNumber';
export const METADATA_PHASE_OF_CASE = METADATA_PANNEL + '.phaseOfCase';
export const METADATA_ICC_NAME = METADATA_PANNEL + '.iccSituationName';
export const METADATA_DATE_ACESSED = METADATA_PANNEL + '.dateAccessed';
export const METADATA_NUMBER_OF_PAGE = METADATA_PANNEL + '.numberOfPages';
export const METADATA_RESPONSIBLE_PARTNER = METADATA_PANNEL + '.responsiblePartner';
export const METADATA_ORIGIN = METADATA_PANNEL + '.origin';
export const METADATA_JUDICAL_DOCUMENT = METADATA_PANNEL + '.judicialDocument';
export const METADATA_URL = METADATA_PANNEL + '.url';
export const METADATA_DATABASE_RECORD_NO = METADATA_PANNEL + '.databaseRecordNumber';
export const METADATA_JUDICAL_DOCUMENT_EN = METADATA_PANNEL + '.judicialDocument';
export const METADATA_INTERNATIONAL_LEGALINSTRUMENT = METADATA_PANNEL + '.internationalLegalinstrument';
export const METADATA_PUBLICATIONS = METADATA_PANNEL + '.publications';
export const METADATA_ICC_PREPARATOR_WORK = METADATA_PANNEL + '.iccPreparatoryWorks';
export const METADATA_DOMESTICLEGALINSTRUMENT = METADATA_PANNEL + '.domesticLegalInstrument';
export const METADATA_NEWS = METADATA_PANNEL + '.news';
export const METADATA_ACADEMIC_WRITING = METADATA_PANNEL + '.academicWriting';
export const METADATA_IMPLEMENTING_LEGISLATION = METADATA_PANNEL + '.implementingLegislation';
export const METADATA_WEBSITE = METADATA_PANNEL + '.website';
export const METADATA_JUDICAL_SUMMARY = METADATA_PANNEL + '.judicialSummary';
export const METADATA_TRANSLATED_TITLE_EN = METADATA_PANNEL + '.translatedTitleEN';
export const METADATA_TRANSLATED_TITLE_FR = METADATA_PANNEL + '.translatedTitleFR';
export const METADATA_SHORT_TITLE = METADATA_PANNEL + '.shortTitle';
export const METADATA_SHORT_TITLE_EN = METADATA_PANNEL + '.shortTitleEN';
export const METADATA_SHORT_TITLE_FR = METADATA_PANNEL + '.shortTitleFR';
export const METADATA_SOURCE = METADATA_PANNEL + '.source';
export const METADATA_SOURCE_TYPE = METADATA_PANNEL + '.sourceType';
export const METADATA_JUDICIAL_BODY = METADATA_PANNEL + '.judicialBody';
export const METADATA_PROSECUTION_SERVICE = METADATA_PANNEL + '.prosecutionService';
export const METADATA_DEFENCE_SERVICE = METADATA_PANNEL + '.defenceService';
export const METADATA_STATE_LEGISLATIVE_BRANCH = METADATA_PANNEL + '.stateLegislativeBranch';
export const METADATA_EXECUTIVE_BRANCH = METADATA_PANNEL + '.stateExecutiveBranch';
export const METADATA_INTERNATIONAL_ORGANISATION = METADATA_PANNEL + '.internationalOrganisation';
export const METADATA_INTERNATIONAL = METADATA_PANNEL + '.international';
export const METADATA_N_G_O = METADATA_PANNEL + '.nonGovernmentalOrganisation';
export const METADATA_PUBLICIST = METADATA_PANNEL + '.publicist';
export const METADATA_COURT_REPORTER = METADATA_PANNEL + '.courtReporter';
export const METADATA_PRESS = METADATA_PANNEL + '.press';
export const METADATA_PARTICIPATING_VICTIM = METADATA_PANNEL + '.participatingVictim';
export const METADATA_OTHER = METADATA_PANNEL + '.other';
export const METADATA_UNKNOWN = METADATA_PANNEL + '.unknown';
export const METADATA_ORGANISATION_STATE_SOURCE = METADATA_PANNEL + '.organisationStateofSource';
export const METADATA_LANGUARE = METADATA_PANNEL + '.language';
export const METADATA_REFERTOTHE_LIST = METADATA_PANNEL + '.refertotheListofLanguagessheet';
export const METADATA_ORIFINAL_LANGUAGE = METADATA_PANNEL + '.originalLanguage';
export const METADATA_NO = METADATA_PANNEL + '.no';
export const METADATA_YES = METADATA_PANNEL + '.yes';
export const METADATA_APPROXIMATE_DATE = METADATA_PANNEL + '.approximateDate';
export const METADATA_DATE_EXPIRY = METADATA_PANNEL + '.dateOfExpiry';
export const METADATA_ENTERY_INTO_FORCE = METADATA_PANNEL + '.entryIntoForce';
export const METADATA_NOTES = METADATA_PANNEL + '.notes';
export const METADATA_SOURCE_URL = METADATA_PANNEL + '.sourceURL';
export const METADATA_RELATED_RESOURCE = METADATA_PANNEL + '.relatedResource';
export const METADATA_ICC_CASECITED = METADATA_PANNEL + '.iccCasecited';
export const METADATA_LEGISLATION = METADATA_PANNEL + '.legislationCited';
export const METADATA_CASE_NAME_ICC = METADATA_PANNEL + '.caseNameICC';
export const METADATA_ALTERNATIVE_VENDORS_SHORT_CASE_TITLE = METADATA_PANNEL + '.alternatiVeandorShortCaseTitle';
export const METADATA_ALTERNATIVE_VENDORS_SHORT_CASE_TITLE_EN = METADATA_PANNEL + '.alternatiVeandorShortCaseTitleEN';
export const METADATA_ALTERNATIVE_VENDORS_SHORT_CASE_TITLE_FR = METADATA_PANNEL + '.alternatiVeandorShortCaseTitleFR';
export const METADATA_CASE_DOCUMENT_NUMBER = METADATA_PANNEL + '.caseDocumentnumber';
export const METADATA_RELATED_CASE = METADATA_PANNEL + '.Relatedcase';
export const METADATA_RELATED_CASE_NUMBER = METADATA_PANNEL + '.Relatedcasenumber';
export const METADATA_KEY_JUDICAL_DOC = METADATA_PANNEL + '.Keyjudicialdocument';
export const METADATA_APPLICATION_ARREST_WARRENT = METADATA_PANNEL + '.Applicationforarrestwarrant';
export const METADATA_DECISION_FACQUITTAL = METADATA_PANNEL + '.Decisionofacquittal';
export const METADATA_DECISION_APPLI_FOR_REST = METADATA_PANNEL + '.Decisiononapplicationforarrest';
export const METADATA_DECISION_CONFIRMATION = METADATA_PANNEL + '.Decisiononconfirmation';
export const METADATA_DECISION_REQ_AUTHORISATION = METADATA_PANNEL + '.Decisiononrequestforauthorisation';
export const METADATA_DECISION_REVIEW = METADATA_PANNEL + '.Decisiononreviewconcerningreduction';
export const METADATA_DECISION_ON_REVISION = METADATA_PANNEL + '.Decisiononrevision';
export const METADATA_DECISION_SENTENCE = METADATA_PANNEL + '.Decisiononsentence';
export const METADATA_DOCUMENT_CONTAINING = METADATA_PANNEL + 'documentContaining';
export const METADATA_JUDGEMENT_ON_APPEAL = METADATA_PANNEL + '.Judgementonappealagainstdecision';
export const METADATA_JUDGEMENT_ON_APPEAL_AGAINST = METADATA_PANNEL + '.Judgementonappealagainst';
export const METADATA_JUDGEMENT_ON_APPEAL_AGAINST_ORDERS =
    METADATA_PANNEL + '.Judgementonappealagainstreparationorders';
export const METADATA_REPARATION_ORDER = METADATA_PANNEL + '.Reparationorder';
export const METADATA_REQUEST_FOR_AUTHORISATION = METADATA_PANNEL + '.Requestforauthorisation';
export const METADATA_NOT_AVAILABLE = METADATA_PANNEL + '.Notavailable';
export const METADATA_NOT_APPLICABLE = METADATA_PANNEL + '.Notapplicable';
export const METADATA_OUT_COME_OF_TRIAL = METADATA_PANNEL + '.Outcomeoftrial';
export const METADATA_ACQUITTAL = METADATA_PANNEL + '.Acquittal';
export const METADATA_CONVICTION = METADATA_PANNEL + '.Conviction';
export const METADATA_CONVICTION_PARTIAL = METADATA_PANNEL + '.Convictionpartial';
export const METADATA_NEW_TRIAL_ORDERED = METADATA_PANNEL + '.Newtrialordered';
export const METADATA_REVISION_ALLOWED = METADATA_PANNEL + '.Revisionallowed';
export const METADATA_PRE_TRIAL = METADATA_PANNEL + '.PreTrial';
export const METADATA_TRIAL = METADATA_PANNEL + '.Trial';
export const METADATA_INTER_LOCUTORY_APPEAL = METADATA_PANNEL + '.Interlocutoryappeal';
export const METADATA_APPEAL = METADATA_PANNEL + '.Appeal';
export const METADATA_REVIEW = METADATA_PANNEL + '.Review';
export const METADATA_REVISION = METADATA_PANNEL + '.Revision';
export const METADATA_ADMISSIBILITY = METADATA_PANNEL + '.Admissibility';
export const METADATA_MERITS = METADATA_PANNEL + '.Merits';
export const METADATA_REMEDY = METADATA_PANNEL + '.Remedy';
export const METADATA_ADVISORY_OPINION = METADATA_PANNEL + '.Advisoryopinion';
export const METADATA_INTER_PRETATION = METADATA_PANNEL + '.Interpretation';
export const METADATA_STRIKING_OUT = METADATA_PANNEL + '.Strikingout';
export const METADATA_FRIENDLY_SETTLEMENT = METADATA_PANNEL + '.Friendlysettlement';
export const METADATA_TO_BE_DETERMINED = METADATA_PANNEL + '.Tobedetermined';
export const METADATA_ARREST_WARRANT = METADATA_PANNEL + '.ArrestWarrantIndictment';
export const METADATA_SUBMISSION = METADATA_PANNEL + '.Submission';
export const METADATA_DIRECTION_AND_ORDER = METADATA_PANNEL + '.DirectionandOrder';
export const METADATA_DECISION = METADATA_PANNEL + '.Decision';
export const METADATA_LEGAL_FINDINGS = METADATA_PANNEL + '.legalFindings';
export const METADATA_JUDGEMENT = METADATA_PANNEL + '.Judgment';
export const METADATA_TRANSCRIPTS = METADATA_PANNEL + '.Transcripts';
export const METADATA_OTHER_COURT_DOCUMENTS = METADATA_PANNEL + '.OtherCourtDocuments';
export const METADATA_COMPOSITION_OF_CHAMBER = METADATA_PANNEL + '.Compositionofchamber';
export const METADATA_PRESIDING_JUDGE = METADATA_PANNEL + '.Presidingjudge';
export const METADATA_PLACE_OF_COURT = METADATA_PANNEL + '.Placeofcourt';
export const METADATA_TYPE_OF_COURT = METADATA_PANNEL + '.Typeofcourt';
export const METADATA_ORDINARY_JURISDICTION = METADATA_PANNEL + '.Ordinaryjurisdiction';
export const METADATA_MILITARY_JURIS_DICTION = METADATA_PANNEL + '.Militaryjurisdiction';
export const METADATA_INTERNATIIONAL_JURIS_DICTION = METADATA_PANNEL + '.Internationaljurisdiction';
export const METADATA_ACCUSED = METADATA_PANNEL + '.accused';
export const METADATA_ALIA_OFACCUSED_NAME = METADATA_PANNEL + '.Aliasofaccusedname';
export const METADATA_PROSECUTOR_TEAM = METADATA_PANNEL + '.Prosecutorteam';
export const METADATA_COUNSEL_FOR_DEFENCE = METADATA_PANNEL + '.Counselfordefence';
export const METADATA_PARTICIPATINGS_STATES = METADATA_PANNEL + '.Participatingstates';
export const METADATA_VICTIMS_PARTICIPATION = METADATA_PANNEL + '.Victimsparticipation';
export const METADATA_COUNSEL_FOR_VICTIMS = METADATA_PANNEL + '.Counselforvictims';
export const METADATA_AMICUSCURIAE = METADATA_PANNEL + '.Amicuscuriae';
export const METADATA_IS_COURT_RECORD = METADATA_PANNEL + '.IsCourtrecord';
export const METADATA_SITUATION_IN_DARFUR = METADATA_PANNEL + '.SituationinDarfur';
export const METADATA_SITUATION_GEORGIA = METADATA_PANNEL + '.SituationinGeorgia';
export const METADATA_SITUATION_IN_LIBYA = METADATA_PANNEL + '.SituationinLibya';
export const METADATA_SITUATION_IN_BOLIVARIANI = METADATA_PANNEL + '.SituationintheBolivarianI';
export const METADATA_SITUATION_IN_BOLIVARIANII = METADATA_PANNEL + '.SituationintheBolivarianII';
export const METADATA_SITUATION_REPUBLIC = METADATA_PANNEL + '.SituationintheCentralAfricanRepublic';
export const METADATA_SITUATION_REPUBLICII = METADATA_PANNEL + '.SituationintheCentralfricanRepublicII';
export const METADATA_SITUATION_REPUBLIC_THE_CONGO = METADATA_PANNEL + '.SituationintheDemocraticRepublicoftheCongo';
export const METADATA_GABONESE_REPUBLIC = METADATA_PANNEL + '.SituationintheGaboneseRepublic';
export const METADATA_REPUBLIC_AFGHANISTAN = METADATA_PANNEL + '.SituationintheIslamicRepublicofAfghanistan';
export const METADATA_SITUATION_IN_THE_PEOPLES = METADATA_PANNEL + '.SituationinthePeoples';
export const METADATA_SITUATION_REPUBLIC_BURUNDI = METADATA_PANNEL + '.SituationintheRepublicofBurundi';
export const METADATA_SITUATION_REPUBLIC_COFCOTE = METADATA_PANNEL + '.SituationintheRepublicofCôte';
export const METADATA_SITUATION_REPUBLIC_KENYA = METADATA_PANNEL + '.SituationintheRepublicofKenya';
export const METADATA_SITUATION_REPUBLIC_MAIL = METADATA_PANNEL + '.SituationintheRepublicofMali';
export const METADATA_SITUATION_IN_PALESTINE = METADATA_PANNEL + '.SituationintheStateofPalestine';
export const METADATA_SITUATION_UGANDA = METADATA_PANNEL + '.SituationinUganda';
export const METADATA_VESSELS = METADATA_PANNEL + '.SituationonRegisteredVessels';
export const METADATA_STATE_OF_BOLIVIA = METADATA_PANNEL + '.SituationinthePlurinationalStateofBolivia';
export const METADATA_REPUBLIC_PHILIPPINES = METADATA_PANNEL + '.SituationintheRepublicofthePhilippines';
export const METADATA_INTER_LOCUTORY_APPEAL_NO = METADATA_PANNEL + '.Interlocutoryappealno';
export const METADATA_AUTHOR = METADATA_PANNEL + '.Author';
export const METADATA_PUBLISHER = METADATA_PANNEL + '.Publisher';
export const METADATA_LEVEL_OF_CONFIDENTIALITY = METADATA_PANNEL + '.Levelofconfidentiality';
export const METADATA_CONFIDENTIAL = METADATA_PANNEL + '.confidential';
export const METADATA_PUBLIC = METADATA_PANNEL + '.public';
export const METADATA_IMPORTANCE = METADATA_PANNEL + '.Importance';
export const METADATA_LESS_IMPORTANT = METADATA_PANNEL + '.Lessimportant';
export const METADATA_IMPORTANT = METADATA_PANNEL + '.Important';
export const METADATA_MOST_IMPORTANT = METADATA_PANNEL + '.Mostimportant';
export const METADATA_COPY_RIGHT_AUTHORISATION = METADATA_PANNEL + '.Copyrightauthorisation';
export const METADATA_SUBJECT = METADATA_PANNEL + '.Subject';
export const METADATA_INTERNATIONAL_CRIMINAL_LAW = METADATA_PANNEL + '.Internationalcriminallaw';
export const METADATA_INTERNATIONAL_HUMAN_RIGHTS_LAW = METADATA_PANNEL + '.Internationalhumanrightslaw';
export const METADATA_INTERNATIONAL_HUMANITARIAN_LAW = METADATA_PANNEL + '.Internationalhumanitarianlaw';
export const METADATA_OTHER_PUBLIC = METADATA_PANNEL + '.Otherpublicinternationallaw';
export const METADATA_LAW_OF_INTERNATIONAL_ORGANISATION = METADATA_PANNEL + '.Lawofinternationalorganisations';
export const METADATA_LAW_OF_THE_SEA = METADATA_PANNEL + '.Lawofthesea';
export const METADATA_PEACE_AGREEMENTS = METADATA_PANNEL + '.Peaceagreements';
export const METADATA_SUBJECT_LEVEL_2 = METADATA_PANNEL + '.SubjectLevel2';
export const METADATA_SUBJECT_LEVEL_3 = METADATA_PANNEL + '.SubjectLevel3';
export const METADATA_SUBJECT_LEVEL_4 = METADATA_PANNEL + '.SubjectLevel4';
export const METADATA_PHASE_OF_PREPARATOR_WORKS = METADATA_PANNEL + '.Phaseofpreparatoryworks';
export const METADATA_EARLY_INITIATIVES = METADATA_PANNEL + '.EarlyInitiatives';
export const METADATA_INTERNATIONAL_LAW_COMMISSION = METADATA_PANNEL + '.InternationalLawCommission';
export const METADATA_AD_HOC_COMMITTEE = METADATA_PANNEL + '.AdHocCommittee';
export const METADATA_PREPARATORY_COMMITTEE = METADATA_PANNEL + '.PreparatoryCommittee';
export const METADATA_ROME_CONFRENCE = METADATA_PANNEL + '.RomeConference';
export const METADATA_PREPARATORY_COMESSION = METADATA_PANNEL + '.PreparatoryCommission';
export const METADATA_PREPARATORY_WORK = METADATA_PANNEL + '.Preparatoryworks';
export const METADATA_RESOURCE_CITATION_ENGLISH = METADATA_PANNEL + '.ResourcecitationEnglish';
export const METADATA_RESOURCE_CITATION_FRENCH = METADATA_PANNEL + '.ResourcecitationFrench';
export const METADATA_KEY_WORD = METADATA_PANNEL + '.Keyword';
export const METADATA_DOCUMENT_TYPE = METADATA_PANNEL + '.Documenttype';
export const METADATA_REAPONSIBLE_PARTNER = METADATA_PANNEL + '.Responsiblepartner';
export const METADATA_METADATA_COLLECTED_BY = METADATA_PANNEL + '.Metadatacollectedby';
export const METADATA_METADATA_FINALISEDON = METADATA_PANNEL + '.Metadatafinalisedon';
export const METADATA_DATE_PUBLISHED_LEGAL_TOOLS = METADATA_PANNEL + '.DatepublishedintheLegalTools';
export const METADATA_DATE_REFISTERED = METADATA_PANNEL + '.Dateregistered';
export const METADATA_DISPLAY_AS = METADATA_PANNEL + '.Displayas';
export const METADATA_INTERNET_DOWNLOAD = METADATA_PANNEL + '.Internetdownload';
export const METADATA_SCANNED_DOCUMENT = METADATA_PANNEL + '.Scanneddocument';
export const METADATA_LIVE_INTERNET_LINK = METADATA_PANNEL + '.Liveinternetlink';
export const METADATA_DOWNLOAD_FROM_AUTHOR = METADATA_PANNEL + '.Downloadfromauthoritativelink';
export const METADATA_DECISION_JURISDICTION = METADATA_PANNEL + '.decisionJurisdiction';
export const METADATA_DECISION_ON_REVIEW = METADATA_PANNEL + '.decisionOnReview';
export const METADATA_ORDER = METADATA_PANNEL + '.order';

//SURVEY
export const SURVEY = 'survey';
export const SURVEY_TITLE_TEXT = SURVEY + '.survey';
export const SURVEY_RESPONSE_TEXT = SURVEY + '.yourResponse';
export const SURVEY_USING_THE_LTD_IN_MY = SURVEY + '.usingTheLTDInMyCapacity';
export const SURVEY_PRACTITIONER = SURVEY + '.practitioner';
export const SURVEY_RESEARCHER = SURVEY + '.researcher';
export const SURVEY_TEACHER = SURVEY + '.teacher';
export const SURVEY_STUDENT = SURVEY + '.student';
export const SURVEY_OTHER = SURVEY + '.other';
export const SURVEY_SUGGESTION_ANY_IMPROVEMENTS = SURVEY + '.suggestionAnyImprovements';
export const SURVEY_DO_NOT_SHOW_AGAIN = SURVEY + '.doNotShowAgain';
export const SURVEY_LATER = SURVEY + '.later';
export const SURVEY_SUBMIT = SURVEY + '.submit';

// SCC
export const SCC = 'scc';
export const SCC_TITLE = SCC + '.title';