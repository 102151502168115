import React, { Component, useContext } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Menu,
    MenuItem,
    Button,
    Divider,
    ListItemText,
    ListItemIcon,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import Theme from '../../Resources/Theme';
import { OAuth, getUserRole } from '../../Features/Authentication/redux-config';
import {
    TDialogVariant,
    ILtdPermissions,
    ICldPermissions,
    ISccPermissions,
} from '../../Features/Authentication/@types';
import utilities from '../../Resources/Utils';
import { HISTORY } from '../../screens/RootLayout';
import { ODetail } from '../../Features/DetailPage/redux-config';
import { withRouter, RouteComponentProps } from 'react-router';
import { OApp } from '../../RootReducer/AppReducer';
import {
    WithTranslationProps,
    withTranslations,
} from '../../Features/Translations/withTranslations';
import {
    GENERAL_DASHBOARD,
    GENERAL_LOGIN,
    GENERAL_LOGOUT,
    GENERAL_READING_LIST,
    GENERAL_SETTINGS,
    GENERAL_SIGNUP,
} from '../../Features/Translations/translationKeys.cld';
import { TutorialFilms } from '../../Features/TutorialFilm/tutorialFilm';
import { AppContext } from '../../Contexts/AppContext';
import { GENERAL_LOG_DESCRIPTION, MENU_LIST_LOGIN, MENU_LIST_SIGN_UP } from '../../Features/Translations/translationKeys.ltd';

interface Props
    extends WithStyles<typeof STYLES>,
    IDispatchProps,
    IStateProps,
    RouteComponentProps,
    WithTranslationProps {
    user?: Record<string, any>;
    variant?: 'ltd' | 'cld' | 'scc';
}

interface IStateProps {
    ltdPermissions: ILtdPermissions;
    cldPermissions: ICldPermissions;
    sccPermissions: ISccPermissions;
}

interface IDispatchProps {
    openAuthDialog?: Function;
    logOut: Function;
    closeDocument: Function;
    confirmDialog: (
        msg: string,
        onConfirm: () => void,
        onCancel: () => void
    ) => void;
}

const UserControl: React.FC<Props> = props => {
    const { context } = useContext(AppContext);

    const { classes, user = {} } = props;
    if (_.isEmpty(user) || !_.get(user, 'email'))
        return (
            <>
                {context === 'ltd' ? TutorialFilms()['tutorial-film-1'] : null}
                <LoggedOut {...props} />
            </>
        );
    else
        return (
            <>
                {context === 'ltd' ? TutorialFilms()['tutorial-film-1'] : null}
                <LoggedIn {...props} />
            </>
        );
};

class LoggedOut extends Component<Props & WithTranslationProps> {

    signup = () => {
        this.props.openAuthDialog!('signup');
    };

    login = () => {
        this.props.openAuthDialog!('login');
    };

    render() {
        const { classes, getTranslation } = this.props;

        return (
            <div className={classes.userCtrlButtonContainer}>
                <Button
                    className={this.props.variant === 'scc' ? classes.sccUserCtrlButton : classes.userCtrlButton}
                    onClick={this.signup}
                >
                    {getTranslation(MENU_LIST_SIGN_UP) || 'signUp'}
                </Button>
                <Button
                    className={this.props.variant === 'scc' ? classes.sccUserCtrlButton : classes.userCtrlButton}
                    onClick={this.login}
                >
                    {getTranslation(MENU_LIST_LOGIN) || 'login'}
                </Button>
            </div>
        );
    }
}

export type TMenuOptionKey =
    | 'readingList'
    | 'logOut'
    | 'ltd_dashboard'
    | 'settings'
    | 'cld_dashboard'
    | 'scc_dashboard';

class LoggedIn extends Component<Props & WithTranslationProps> {

    state = {
        open: null,
    };
    logout = () => {
        this.props.confirmDialog(
            `${this.props.getTranslation(GENERAL_LOG_DESCRIPTION) || 'Are you sure you want to log out?'}`,

            this.handleLogout,
            () => this.setState({ open: null })
        );
    };

    handleLogout = async () => {
        // const location = _.get(this.props, 'location.pathname').replace(/\/$/, '');
        const location = window.location.pathname;

        try {
            if (/\/readinglist.*/i.test(location))
                this.props.history.replace('/cld');
            await utilities.logoutUser();
            this.props.logOut();
        } catch (error) { }
    };

    onClose = () => {
        this.setState({ open: null });
    };

    handleClick = event => {
        this.setState({ open: event.currentTarget });
    };

    gotoReadingList = () => {
        this.props.closeDocument();
        HISTORY.push('/readinglist');
        this.onClose();
    };
    gotoSettings = () => {
        this.props.closeDocument();
        HISTORY.push(`/settings`);
        this.onClose();
    };

    gotoDashboard = route => () => {
        this.props.closeDocument();
        HISTORY.push(route);
        this.onClose();
    };

    render() {
        const { open } = this.state;
        const {
            user,
            classes,
            ltdPermissions,
            cldPermissions,
            sccPermissions,
            getTranslation,
            variant = 'ltd'
        } = this.props;

        const getDashBoardOption = (
            variant: 'ltd' | 'cld' | 'scc' = 'ltd'
        ): {
            label: string;
            icon: string;
            onClick: (event) => void;
            key: TMenuOptionKey;
        } => {
            if (variant === 'cld')
                return {
                    label: getTranslation(GENERAL_DASHBOARD),
                    icon: 'settings_input_component',
                    onClick: this.gotoDashboard('/cld-dashboard'),
                    key: 'cld_dashboard',
                };
            else if (variant === 'scc')
                return {
                    label: getTranslation(GENERAL_DASHBOARD),
                    icon: 'settings_input_component',
                    onClick: this.gotoDashboard('/scc-dashboard'),
                    key: 'scc_dashboard',
                };
            return {
                label: getTranslation(GENERAL_DASHBOARD),
                icon: 'settings_input_component',
                onClick: this.gotoDashboard('/ltd-dashboard'),
                key: 'ltd_dashboard',
            };
        };

        const MENU_OPTIONS: {
            label: string;
            icon: string;
            onClick: (event) => void;
            key: TMenuOptionKey;
        }[] = [
            getDashBoardOption(variant),
            {
                label: getTranslation(GENERAL_READING_LIST),
                icon: 'bookmark',
                onClick: this.gotoReadingList,
                key: 'readingList',
            },
            {
                label: getTranslation(GENERAL_SETTINGS),
                icon: 'settings',
                onClick: this.gotoSettings,
                key: 'settings',
            },
            {
                label: getTranslation(GENERAL_LOGOUT),
                icon: 'exit_to_app',
                onClick: this.logout,
                key: 'logOut',
            },
        ];
        
        const _MENU_OPTIONS = _.filter(
            MENU_OPTIONS,
            option =>
                _.indexOf(
                    [...ltdPermissions.appMenuOptions, ...cldPermissions.appMenuOptions, ...sccPermissions.appMenuOptions],
                    option.key
                ) > -1
        );

        return (
            <div className={classes.userCtrlButtonContainer}>
                <Button
                    className={this.props.variant === 'scc' ? classes.sccUserCtrlButton : classes.userCtrlButton}
                    aria-owns={open ? 'menu' : undefined}
                    aria-haspopup='true'
                    onClick={this.handleClick}
                >
                    <span className={classes.label}>{_.get(user, 'name') || _.get(user, 'username')} </span>
                    <span className={classes.label}>
                        <i className='material-icons'>keyboard_arrow_down</i>
                    </span>
                </Button>
                <Menu elevation={1} id='menu' anchorEl={open} open={Boolean(open)} onClose={this.onClose}>
                    {_MENU_OPTIONS.map(menu => (
                        <MenuItem dense key={menu.key} onClick={menu.onClick}>
                            <ListItemIcon>
                                <i className='material-icons'>{menu.icon}</i>
                            </ListItemIcon>
                            <ListItemText>{menu.label}</ListItemText>
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    ..._.pick(state.Auth, ['ltdPermissions', 'cldPermissions', 'sccPermissions']),
});

const mapDispatchToProps = dispatch => ({
    openAuthDialog: (variant: TDialogVariant) =>
        dispatch(OAuth.openAuthDialog(variant)),
    logOut: () =>
        dispatch({
            type: OAuth.actions.USER_LOGGED_OUT,
        }),
    closeDocument: () => dispatch(ODetail.closeDocument(true)),
    confirmDialog: (msg, onConfirm, onCancel) =>
        dispatch(OApp.showConfirmationDialog(msg, onConfirm, onCancel)),
});

const STYLES = theme =>
    createStyles({
        userCtrlButton: {
            lineHeight: 1.2,
            [theme.breakpoints.down('sm')]: {
                marginLeft: 24,
            },
            [theme.breakpoints.up('sm')]: {
                color: Theme.Colors.Grayscale.White,
            },
            fontSize: 14,
        },
        sccUserCtrlButton: {
            [theme.breakpoints.up('sm')]: {
                color: Theme.Colors.Grayscale.Black,
            },
        },
        label: {
            display: 'flex',
            alignItems: 'center',
        },
        userCtrlButtonContainer: {
            display: 'flex',
            margin: '0px 8px',
            alignItems: 'center',
        },
    });

export default withTranslations(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(withStyles(STYLES)(UserControl))
    )
);


